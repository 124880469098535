

<template>
  <section class="banner blog-banner">
      <div class="container text-center">
          <div class="content-wrapper">
              <RouterLink to="/" class="logo">
                  <img src="../assets/images/logo.svg" alt="Logo">
              </RouterLink><br/>
             
              <h1>{{ modifiedTitle }}</h1>
              
          </div>
      </div>
  </section>

 

    <section class="blog-single">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="single-blog-content">
                        <RouterLink to="/" class="btn-style icon"><img src="../assets/images/icons/left-arrow-icon.svg" alt="icon">back</RouterLink>
                        <p v-html="mainArticle.content">
                                   
                                </p>

                        
                    </div>
                </div>
                <div class="col-md-4">
                    <a href="#" class="our-companies-card active blog-our-companies-card">
                        <div class="content">
                            <p class="gray">Got a project idea?</p>
                            <h3 class="mail light">write us at <br>
                                <span>hello@fomocrew.io</span></h3>
                        </div>
                    </a>
                    <div v-for="articolo in randomArticles.slice(0, 2)" :key="articolo.id" class="blog-card vertical-card">
                        <div class="img-wrapper">
                            <img :src="articolo.image" alt="img">
                        </div>
                        <a :href="`/blog/${articolo.title}`" :key="articolo.title">
                        <div class="content-wrapper">
                            <p class="p medium">{{ articolo.title.replace(/-/g, " ") }}</p>
                        </div>
                        </a>
                    </div>
                   
                </div>
            </div>
        </div>
    </section>


    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="footer-content">
                        <a href="#" class="logo">
                            <img src="../assets/images/footer-logo.svg" alt="logo">
                        </a>
                        <p>We empower fast-growing companies to build digital experiences
and launch go-to-market initiatives. </p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="link-wrapper">
                        <p class="medium">Company</p>
                        <ul>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">News</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">FAQ</a></li>s
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="link-wrapper">
                        <p class="medium">Need Help</p>
                        <ul>
                            <li><a href="mailto:hello@fomocrew.io">hello@fomocrew.io</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="link-wrapper">
                        <p class="medium">Location</p>
                        <ul>
                            <li>FOMO CREW FZCO<br/>Dubai Digital Park - Office A2, <br>
                                United Arab Emirates</li>
                        </ul>
                    </div>
                    <div class="social-wrapper">
                        <p>Socials</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/fb-icon.svg" alt="icon"></a></li>
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/instagram-icon.svg" alt="icon"></a></li>
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/twitter-icon.svg" alt="icon"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>
import axios from 'axios';

export default {
    name: 'AboutView',
    components: {
    },
    data() {
        return {
            activeTab: '1',
            randomArticles: [],
            mainArticle: [],
        };
    },
    computed: {
    modifiedTitle() {
      if (this.mainArticle && this.mainArticle.title) {
        return this.mainArticle.title.replace(/-/g, ' ');
      }
      return '';
    }
  },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get('https://blog.behindthis.app/api/fomocrew/rAVaNDetANYoNtaChous/article/list')
                .then((response) => {
                    this.randomArticles = response.data;
                });

            axios
                .get('https://blog.behindthis.app/api/fomocrew/rAVaNDetANYoNtaChous/article/'+this.$route.params.slug)
                .then((response) => {
                    this.mainArticle = response.data;
                });
        },

    },
};
</script>


<style lang="scss" scoped>

</style>
