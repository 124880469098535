<template>
  <section class="banner">
      <div class="container text-center">
          <div class="content-wrapper" style="margin-bottom:90px;">
              <RouterLink to="/" class="logo">
                  <img src="../assets/images/logo.svg" alt="Logo">
              </RouterLink><br/>
              <h1>Growing is good, <span>evolving</span> is better.</h1>
              <h3 style="margin-bottom:20px !important;">We empower fast-growing companies to build digital
                  experiences and launch go-to-market initiatives.<br/><br/>
                  </h3>
                  <a href="https://office.fomocrew.io/index.php?rp=/login" class="bottoniauth">Login</a><a href="https://office.fomocrew.io/index.php?rp=/store" class="bottoniauth">Available Services</a>
                  
          </div>
          <div class="our-companies-wrapper">
              <p class="p medium gray arrow">our companies</p>
              <div class="main-card-wrapper">
                  <a href="https://3rdshift.io/" target="_blank" class="our-companies-card">
                      <img src="../assets/images/3rdshift.svg" alt="img" style="width:270px;">
                  </a>
                  <a href="https://reputazi.com/" target="_blank" class="our-companies-card">
                      <img src="../assets/images/our-companies-logo-2.svg" alt="img">
                  </a>
                  <a href="https://bridge2vc.com/" target="_blank" class="our-companies-card">
                      <img src="../assets/images/bridge2vcwhite.png" alt="img" style="width:280px;">
                  </a>
                  <a href="mailto:hello@fomocrew.io" class="our-companies-card">
                      <div class="content">
                          <h3 class="light">Got a project idea?</h3>
                          <h3 class="mail light">write us at <span>hello@fomocrew.io</span></h3>
                      </div>
                  </a>
              </div>
          </div>
      </div>
  </section>

    <!-- <section class="team">
        <div class="container text-center">
            <h3>Team</h3>
            <h2>The Management</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="team-member-card">
                        <div class="img-wrapper">
                            <img src="../assets/images/the-management-author-img-1.png" alt="img">
                        </div>
                        <div class="content-wrapper">
                            <p class="p medium"></p>
                            <p class="p medium gray">Founder & CEO</p>
                             <a target="_blank" href="https://www.linkedin.com/in//"><img src="../assets/images/icons/in-icon.svg" alt="icon"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="team-member-card">
                        <div class="img-wrapper">
                            <img src="../assets/images/the-management-author-img-2.png" alt="img">
                        </div>
                        <div class="content-wrapper">
                            <p class="p medium"></p>
                            <p class="p medium gray">Founder & CTO</p>
                            <a target="_blank" href="https://www.linkedin.com/in//"><img src="../assets/images/icons/in-icon.svg" alt="icon"></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="team-member-card">
                        <div class="img-wrapper">
                            <img src="../assets/images/choi.png" alt="img">
                        </div>
                        <div class="content-wrapper">
                            <p class="p medium">Kyeong Ju Choi</p>
                            <p class="p medium gray">COO</p>
                            <a target="_blank" href=""><img src="../assets/images/icons/in-icon.svg" alt="icon"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section class="blog">
        <div class="container">
            <h3>News</h3>
            <h2>Latest stories</h2>
            <div class="row">
                <!-- articolo -->
                <div v-for="articolo in randomArticles.slice(-2).reverse()" :key="articolo.id" class="col-md-6">
                    <div class="blog-card">
                        <div class="img-wrapper">
                            <img :src="articolo.image" alt="img">
                        </div>
                        <div class="content-wrapper">
                            <p class="p medium">{{ articolo.title.replace(/-/g, " ") }}</p>
                            <div class="footer">
                                <p class="medium gray">by FomoCrew</p>
                                <RouterLink :to="`/blog/${articolo.title} `" class="a">read more</RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    </section>

    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="footer-content">
                        <a href="#" class="logo">
                            <img src="../assets/images/footer-logo.svg" alt="logo">
                        </a>
                        <p>We empower fast-growing companies to build digital experiences
and launch go-to-market initiatives. </p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="link-wrapper">
                        <p class="medium">Company</p>
                        <ul>
                           
                        
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">News</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">FAQ</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="link-wrapper">
                        <p class="medium">Need Help</p>
                        <ul>
                            <li><a href="mailto:hello@fomocrew.io">hello@fomocrew.io</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="link-wrapper">
                        <p class="medium">Location</p>
                        <ul>
                            <li><b>FOMO CREW FZCO</b><br/>Dubai Digital Park - Office A2, <br>
                                United Arab Emirates</li>
                        </ul>
                    </div>
                    <div class="social-wrapper">
                        <p>Socials</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/fb-icon.svg" alt="icon"></a></li>
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/instagram-icon.svg" alt="icon"></a></li>
                            <li><a href="#" target="_blank"><img src="../assets/images/icons/twitter-icon.svg" alt="icon"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>

import axios from 'axios';
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
  },
  data() {
        return {
            activeTab: '1',
            randomArticles: [],
        };
    },
  mounted(){
        this.load();
  },

  methods:{
    load() {
            axios
                .get('https://blog.behindthis.app/api/fomocrew/rAVaNDetANYoNtaChous/article/list')
                .then((response) => {
                    this.randomArticles = response.data;
                });
        },

  }
}
</script>

<style lang="scss" scoped>

</style>
