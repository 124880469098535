import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import Blog from "@/views/BlogView.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/blog/:slug",
        name: "Blog",
        component: Blog,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});




export default router;
